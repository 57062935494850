/***************************************************************************************************
 * Load `$localize` onto the global scope - used if i18n tags appear in Angular templates.
 */
import '@angular/localize/init';
// This file includes polyfills needed by Angular and is loaded before the app.
// You can add your own extra polyfills to this file.
import 'core-js/es/symbol';
import 'core-js/es/object';
import 'core-js/es/function';
import 'core-js/es/parse-int';
import 'core-js/es/parse-float';
import 'core-js/es/number';
import 'core-js/es/math';
import 'core-js/es/string';
import 'core-js/es/date';
import 'core-js/es/array';
import 'core-js/es/regexp';
import 'core-js/es/map';
import 'core-js/es/set';
import 'core-js/es/reflect';

import 'core-js/web/url';
import 'core-js/web/url-search-params';

import 'reflect-metadata';

// If you need to support the browsers/features below, uncomment the import
// and run `npm install import-name-here';
// Learn more in https://angular.io/docs/ts/latest/guide/browser-support.html

// Animations
// Needed for: All but Chrome and Firefox, Not supported in IE9
import 'web-animations-js';

// Important to keep this import AFTER the web-animation-js import, it allows
// to pass Karma test with PhantomJs.
// Learn more in https://github.com/angular/angular/issues/15586
import 'zone.js';

// NgClass on SVG elements
// Needed for: IE10, IE11
import 'classlist';
